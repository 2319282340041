<template>
  <article class="section3">
    <div v-if="!isMobile" class="s3-bg relative" style="height: 100vh">
      <img
        loading="lazy"
        data-aos="fade"
        data-aos-delay="500"
        class="absolute"
        style="top: 20vw; width: 50vw"
        src="./s3/pc-style.png"
          :alt="`${info.caseName}style`"
      />
      <div class="flex justify-around">
        <div class="flex flex-col" style="padding-top: 3%">
<h3 class="mb-4 text-white title"
            data-aos="fade-up"
            data-aos-delay="500">建設齊發<span>潛力爆棚</span><b>。</b></h3>
          <p
            data-aos="fade-up"
            data-aos-delay="500"
            class="flex items-center text-primary-200 text-1.4 font-weight-900 tracking-wide font-secondary"
          >
            <span>Golden Future</span>
            <span class="s3-line mx-3" style="width: 2vw"></span>
            <span style="font-family: Noto Serif CJK TC, serif"
              >重磅建設。潛力。獨樹一格</span
            >
          </p>
          <!-- Swiper -->
          <div class="mt-20 relative swiper_pc">
            <swiper
              :options="swiperOptions"
              ref="swiperSection3"
              @slideChangeTransitionStart="slidechange"
            >
              <swiper-slide
                class="slide"
                v-for="(slide, i) in slides"
                v-bind:key="i"
                v-bind:style="{
                  backgroundImage: `url(${slide.img})`,
                }"
              >
                <div class="caption">
                  {{ slide.caption }}
                </div>
              </swiper-slide>
            </swiper>
            <img
              loading="lazy"
              class="absolute"
              style="right: -0.8vw; top: -0.8vw"
              src="./s3/pc-slider_style.svg"
          :alt="`${info.caseName}slider_style`"
            />

            <!--  -->
            <div class="flex justify-end mt-3" style="width: 42vw">
              <div class="swiper-pagination" slot="pagination"></div>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          class="flex flex-col text-left text"
          
        >
          <h4 class="flex mb-10 relative">
            <span class="text-white text-1.6">紅樹林雙捷</span>
            <span class="text-primary-300 text-1.6 mx-1">。</span>
            <span class="text-white text-1.6">二大建設翻轉城市未來</span>
            <img
              loading="lazy"
              class="absolute blink"
              style="width: 3.5vw; height: 3.5vw; top: -2vw; right: 10vw"
              src="./s3/pc-star.svg"
          :alt="`${info.caseName}star`"
            />
          </h4>
          <div class="text-white text-1 leading-loose mb-14">
            <p>
              紅樹林雙捷站、洲美快速道路，快捷交通動能，迅速連接紅樹林與大台北都會生活圈
            </p>
            <p>
              淡江大橋、淡北快速道路，二大交通建設，未來將快速縮短北市與紅樹林之間的交通時間，
            </p>
            <p>成為台北都會最具潛力的國際景觀城市</p>
          </div>
          <div class="relative">
            <span class="text-primary-100 text-2 font-weight-900"
              >「機會，是給懂得把握的人」</span
            >
            <img
              loading="lazy"
              class="absolute blink"
              style="width: 3.5vw; height: 3.5vw; top: 2vw; left: -2.5vw"
              src="./s3/pc-star.svg"
          :alt="`${info.caseName}star`"
            />
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="mb-s3-bg relative">
      <!--span
        data-aos="fade"
        data-aos-delay="400"
        class="absolute text-white text-3.5 z-10"
        style="bottom: 5vw; right: 5vw; font-weight: bold"
        >淡江大橋示意圖</span
      -->
      <div data-aos="fade-up" data-aos-delay="400">
        <span
          class="text-primary-200 font-weight-900 tracking-wide font-secondary"
          style="font-size: 11vw; opacity: 0.23"
          >Golden Future</span
        >
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="400"
        class="flex flex-col"
        style="margin-left: 7vw"
      >
<h3 class="mb-2 text-white title">建設齊發<span>潛力爆棚</span><b>。</b></h3>
        <h4 class="flex relative my-4">
          <span class="text-white text-4.5">紅樹林雙捷</span>
          <span class="text-primary-300 text-4.5 mx-1">。</span>
          <span class="text-white text-4.5">二大建設翻轉城市未來</span>
        </h4>
        <div
          data-aos="fade-up"
          data-aos-delay="400"
          class="text-white text-left text-3.5 mb-7 leading-relaxed"
          style="width: 90vw"
        >
          <p>
            紅樹林雙捷站、洲美快速道路，快捷交通動能，迅速連接紅樹林與大台北都會生活圈。淡江大橋、淡北快速道路，二大交通建設，成為最具潛力的國際景觀城市
          </p>
        </div>
        <div data-aos="fade-up" data-aos-delay="400" class="text-left">
          <span class="text-primary-100 text-5 font-weight-900"
            >「機會，是給懂得把握的人」</span
          >
        </div>
      </div>
      <!-- Swiper -->
      <div class="mt-7 relative swiper_mb">
        <swiper
          :options="swiperOptions"
          ref="swiperSection3"
          @slideChangeTransitionStart="slidechange"
        >
          <swiper-slide
            class="slide"
            v-for="(slide, i) in slides"
            v-bind:key="i"
            v-bind:style="{
              backgroundImage: `url(${slide.img})`,
            }"
          >
            <div class="caption">
              {{ slide.caption }}
            </div>
          </swiper-slide>
        </swiper>

        <!--  -->
        <div class="flex justify-center mt-3" style="width: 100vw">
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
      </div>
    </div>
  </article>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
@import '@/assets/style/animate.scss';
.blink {
  @include animate(fadeIn, 0.8s, 0);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  pointer-events: none;
}
.title{
  text-align: left;
  font-size: size(62);
  letter-spacing: .07em;
  white-space: nowrap;
  span{
    font-weight: 900;margin: 0 0 0 .3em;
    font-size: 1.01em;
  }
  b{font-weight: 900;position: relative;top:.3em;left: -.04em;}
}

/* 螢幕尺寸標準 */
.s3-bg {
  background-image: url('./s3/pc-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100% 100%;
}
.text{
  display: flex;
  justify-content: center;
  height: calc(1080 * 100vw / 1920);
}

.s3-line {
  background-color: #a3a3a3;
  height: 1px;
}
.swiper_pc {
  .swiper-container {
    height: calc(578 * 100vw / 1920);
    width: 42vw;
    height: 22vw;
    margin: 0;
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      .caption {
        position: absolute;
        z-index: 1;
        font-weight: bold;
        color: #fff;
        right: 1.5vw;
        bottom: 0.8vw;
        font-size: 1vw;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .swiper-button-prev {
      left: 0;
      bottom: 0;
      width: size(80);
      height: size(80);
      color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;

      &::after {
        content: '';
      }
    }
    .swiper-button-next {
      right: 0;
      color: #fff;
      width: size(80);
      height: size(80);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;

      &::after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      margin-left: 16px;
      border-radius: 0%;
      background: white;
      width: size(70);
      height: size(5);
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: #54c1bd;
      }
    }
  }
}

.swiper_mb {
  .swiper-container {
    height: calc(578 * 100vw / 1920);
    width: 100%;
    height: 57vw;
    margin: 0;
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      .caption {
        position: absolute;
        z-index: 1;
        color: #fff;
        right: 5vw;
        bottom: 2vw;
        bottom: 3vw;
        font-size: 3.5vw;
        font-weight: bold;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .swiper-button-prev {
      left: 0;
      bottom: 0;
      width: size(80);
      height: size(80);
      color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;

      &::after {
        content: '';
      }
    }
    .swiper-button-next {
      right: 0;
      color: #fff;
      width: size(80);
      height: size(80);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;

      &::after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      margin-left: 16px;
      border-radius: 0%;
      background: white;
      width: 8vw;
      height: 1vw;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: #54c1bd;
      }
    }
  }
}
// Mobile

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .mb-s3-bg {
    height: calc(590 * 100vw / 375);
    background-image: url('./s3/mb-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 60% 0;
}
.title{
  font-size: size-m(34);
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import info from "@/info";
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'section3',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      info,
      isMobile,
      activeSlide: {},
      slides: [
        {
          img: require('@/projects/ar/s3/pc-slider_1.jpg'),
          caption: '洲美快速道路',
        },
        {
          img: require('@/projects/ar/s3/pc-slider_2.jpg'),
          caption: '淡北快速道路示意圖',
        },
        {
          img: require('@/projects/ar/s3/pc-slider_3.jpg'),
          caption: '淡江大橋示意圖',
        },
        {
          img: require('@/projects/ar/s3/pc-slider_4.jpg'),
          caption: '雙線捷運-紅樹林站',
        },
      ],
      swiperOptions: {
        effect: 'fade',
        speed: 1200,
        spaceBetween: 30,
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section3 .swiper-pagination',
          clickable: true,
        },
        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: false,
        // },
      },
    }
  },

  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection3.$swiper.realIndex
      this.activeSlide = this.slides[activeIndex]
    },
    prev() {
      this.$refs.swiperSection3.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection3.$swiper.slideNext()
    },
  },

  mounted() {
    this.activeSlide = this.slides[0]
  },

  created() {},
}
</script>
