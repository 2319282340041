<template>
  <article class="section10">
    <!-- PC -->
    <div
      v-if="!isMobile"
      class="s10-bg relative flex justify-center items-center"
      style="height: 100vh"
    >
  <div class="en_title text-white font-weight-900 font-secondary"
            >Professional Team</div>
      <div
        data-aos="fade-up"
        data-aos-delay="500"
        class="flex flex-col items-center"
      >
        <img loading="lazy"
          class="p-5 s10-block"
          style="width: 18vw; height: 18vw; margin-bottom: 3%"
          src="./s10/pc-title.svg"
          :alt="`${info.caseName}title`"
        />
        <p class="text-white font-weight-900 text-1.7 font-secondary">
          初心生活 不同凡響
        </p>
        <p class="text-white font-weight-900 text-1.5 font-secondary mb-3">
          Original life, Unique Vision
        </p>
        <h3 class="text-primary-300 font-weight-900 font-secondary text-1.8">
          紅樹林雙捷站｜26-45坪｜限量SC鋼骨｜環景美學建築
        </h3>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="mb-s10-bg h-screen">
      <div
        data-aos="fade-up"
        data-aos-delay="500"
        class="flex flex-col items-center"
      >
        <img loading="lazy"
          class="p-5 s10-block"
          style="width: 50vw; height: 50vw; margin-top: 30%; margin-bottom: 14%"
          src="./s10/pc-title.svg"
          :alt="`${info.caseName}-title`"
        />
        <p class="text-white font-weight-900 text-6 font-secondary">
          初心生活 不同凡響
        </p>
        <p class="text-white font-weight-900 text-5.5 font-secondary mb-10">
          Original life, Unique Vision
        </p>
        <h3 class="text-primary-300 font-weight-900 font-secondary text-6">
          紅樹林雙捷站｜26-45坪
        </h3>
        <h3 class="text-primary-300 font-weight-900 font-secondary text-6">
          限量SC鋼骨｜環景美學建築
        </h3>
      </div>
    </div>
  </article>
</template>

<script>
import info from "@/info";
import { isMobile } from '@/utils'

export default {
  data() {
    return {
      info,
      isMobile,
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.en_title{
  top: 0;
  right: 0;
  position:absolute;
  font-size: 5.2vw;
  opacity: .18;
  transform: rotate(90deg) translate(101% , -10%);
  transform-origin:100% 0;
  white-space: nowrap;
}

.s10-bg {
  background-image: url('./s10/pc-bg.jpg');
  background-size: cover;
}
.s10-block {
  background-color: rgba(35, 24, 21, 0.72);
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.16);
}
@media only screen and (max-width: 767px) {
  .mb-s10-bg {
    background-image: url('./s10/mb-bg.jpg');
    background-size: cover;
  }
}
</style>
