<template>
  <article class="section5">
    <!-- PC -->
    <div v-if="!isMobile" class="s5-bg relative" style="height: 90vh">
      <img loading="lazy"
        class="absolute"
        style="bottom: 0vw; width: 40vw; z-index: 1"
        src="./s5/pc-style.png"
          :alt="`${info.caseName}style`"
      />
      <div
        data-aos="fade-up"
        data-aos-delay="500"
        class="flex flex-col"
        style="padding-top: 5%; padding-left: 4%"
      >
      <h3 class="mb-4 title"><span>登峰景致</span><b>。</b>菁英富豪必收藏</h3>
        <p
          class="flex items-center text-primary-400 text-1.4 font-weight-900 tracking-wide font-secondary"
        >
          <span>Honorable Achievement</span>
          <span class="s5-line mx-3" style="width: 2vw"></span>
          <span style="font-family: Noto Serif CJK TC, serif"
            >收藏經典。安全美學。獨樹一格</span
          >
        </p>
        <h4 class="flex mb-10 relative" style="margin-top: 10%">
          <span class="text-primary-500 text-1.6"
            >凌空百米地標 環景美學建築</span
          >
          <span class="text-primary-300 text-1.6 mx-1">。</span>
          <span class="text-primary-500 text-1.6">眼界決定你的成就</span>
        </h4>
        <div class="text-primary-500 text-1 leading-loose text-left mb-14">
          <p>凌空百米的地標氣勢、全正面建築環景美學霸氣呈現</p>
          <p>一座融合自然景觀與人文的現代簡約建築，傲然獨立於紅樹林國際灣區</p>
          <p>我們堅持以北市豪宅規格設計26-45坪的難得的精品景觀宅</p>
          <p>讓您享有收藏「紅樹林富人灣」的非凡成就</p>
        </div>
        <span
          class="text-primary-200 text-2 font-weight-900 text-left"
          style="z-index: 2"
          >收藏價值，成就非凡夢想</span
        >
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="mb-s5-bg relative" style="">
     
      <img loading="lazy"
        data-aos="fade"
        data-aos-delay="400"
        class="absolute"
        style="right: 0vw; top: 41%; width: 91vw"
        src="./s5/pc-img_style.svg"
          :alt="`${info.caseName}img_style`"
      />
      <img loading="lazy"
        data-aos="fade"
        data-aos-delay="400"
        class="absolute"
        style="width: 100vw; right: 0vw; top: 30%"
        src="./s5/pc-img.png"
          :alt="`${info.caseName}img`"
      />

      <span
        data-aos="fade"
        data-aos-delay="400"
        class="text-white absolute"
        style="top: 160vw; right: 1.5vh; z-index: 100; font-size: 3vw;font-weight: bold;"
        >建築外觀3D透視圖</span
      >
      <div data-aos="fade-up" data-aos-delay="400">
        <span
          class="text-primary-200 font-weight-900 font-secondary"
          style="font-size: 6.8vw; opacity: 0.23"
          >Honorable Achievement</span
        >
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="400"
        class="flex flex-col"
        style="margin-left: 7vw"
      > 
      <h3 class="title mb-2" data-aos="fade"
        data-aos-delay="400"><span>登峰景致</span><b>。</b>菁英富豪必收藏</h3>
        <h4 class="flex relative my-4">
          <span class="text-primary-500 text-4.5">凌空百米地標</span>
          <span class="text-primary-300 text-4.5 mx-1">。</span>
          <span class="text-primary-500 text-4.5">環景美學建築</span>
        </h4>
        <div
          class="text-primary-500 text-left text-3.5 mb-7 leading-relaxed"
          style="width: 90vw"
        >
          <p>
            凌空百米的地標氣勢、全正面建築環景美學霸氣呈現，讓您享有收藏「紅樹林富人灣」的非凡成就
          </p>
        </div>
        <div class="text-left">
          <span class="text-primary-200 text-5 font-weight-900"
            >收藏價值，成就非凡夢想</span
          >
        </div>
        <img loading="lazy"
          data-aos="fade"
          data-aos-delay="400"
          style="
            height: 37vw;
            width: 100vw;
            margin-top: 93%;
            margin-left: -8%;
            z-index: index 50;
          "
          src="./s5/pc-style.png"
          :alt="`${info.caseName}style`"
        />
      </div>
    </div>
  </article>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
/* 螢幕尺寸標準 */
// .section5{
//   padding: 0 0 8vw;
//   position: relative;
// }
.title{
  text-align: left;
  font-size: size(62);
  letter-spacing: .07em;
  white-space: nowrap;
  span{
    font-weight: 900;margin: 0;
    font-size: 1.01em;
  }
  b{font-weight: 900;position: relative;top:.3em;margin:0 -.08em 0 -.26em;}
}
.s5-line {
  background-color: #6e6e6e;
  height: 1px;
}

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
.title{
  font-size: size-m(25.8);
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import info from "@/info";
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
// import 'swiper/css/swiper.css'

export default {
  name: 'section5',

  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      info,
      isMobile,
      activeSlide: {},
   /*   title: '／亞東生活圈 都心璀璨一等地／',
      content: `近亞東醫院站、湳雅夜市、台65線<br/>遠東科技園區 Google、亞馬遜國際企業插旗<br/>更近板泰輕軌預劃路線 連袂鼎起最耀眼將來`,
      slides: [
        {
          img: require('@/projects/ryc/s5/pc_slider_1.jpg'),
          caption: '新北科技園區',
        },
        {
          img: require('@/projects/ryc/s5/pc_slider_2.jpg'),
          caption: '湯城廣場',
        },
        {
          img: require('@/projects/ryc/s5/pc_slider_3.jpg'),
          caption: '新北第二行政中心基地',
        },
      ],
      swiperOptions: {
        effect: 'fade',
        speed: 1200,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section5 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
      */
    }
  },

  methods: {
    slidechange() {
      const activeIndex = this.$refs.swiperSection5.$swiper.realIndex
      this.activeSlide = this.slides[activeIndex]
    },
    prev() {
      this.$refs.swiperSection5.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection5.$swiper.slideNext()
    },
  },

  mounted() {
    this.activeSlide = this.slides[0]
  },

  created() {},
}
</script>
