export default {
  address: '新北市淡水區中正東路二段77號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3611.4553298153687!2d121.45750701500873!3d25.15409888391615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442af92fe8470a7%3A0xef6702ca9e6c151d!2zMjUx5paw5YyX5biC5reh5rC05Y2A5Lit5q2j5p2x6Lev5LqM5q61NzfomZ8!5e0!3m2!1szh-TW!2stw!4v1641785779605!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/FaWB3A3DavJATFzt8',
  phone: '02-8809-6688',
  fbLink:
    'https://www.facebook.com/%E5%A0%85%E6%8C%81%E5%88%9D%E5%BF%83-%E5%B2%B3%E6%B3%B0%E5%B3%B0%E7%A2%A920-105502225299047',
  fbMessage:
    'https://m.me/105502225299047/',
  caseName: '岳泰峰碩',
  indigatorLength: 10,
  houseInfos: [
    ['建設公司', '岳泰建設股份有限公司'],
    ['格局規劃', '二房(26坪) 三房(32~45坪) '],
    ['棟戶規劃', '198戶'],
    ['基地面績', '約1311坪'],
    ['樓層規劃','地上23層、地下4層',],
    ['結構設計','SC鋼骨構造+CFT工法',],
    ['建造號碼','103淡建字號00574號',],
    ['基地地址', '新北市淡水區海天段359、359-6號地號'],
  ],

  gtmCode: ['5GQPZ9Z','PXDTNH5S'], // 可放置多個
  recaptcha_site_key_v2: '6LfGUjEaAAAAANYvhZQx5imGm23pRt0V-rOvkpNC',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '聯絡我們',
    subTitle: '若想了解更多資訊，歡迎填寫表單或來電洽詢，將由專人為您服務，謝謝！',
  },
}
