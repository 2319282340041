<template>
  <article class="section7">
    <!-- PC -->
    <div v-if="!isMobile" class="s7-bg relative" style="height: 90vh">
      <img loading="lazy"
        data-aos="fade"
        data-aos-delay="500"
        class="absolute"
        style="right: 12vh; top: 3vh; width: 70vh"
        src="./s7/pc-img_style.svg"
          :alt="`${info.caseName}img_style`"
      />
      <img loading="lazy"
        data-aos="fade"
        data-aos-delay="500"
        class="absolute"
        style="right: 15vh; top: 5vh; width: 70vh"
        src="./s7/pc-img.jpg"
          :alt="`${info.caseName}img`"
      />
      <img loading="lazy"
        data-aos="fade"
        data-aos-delay="500"
        class="absolute"
        style="left: 0vh; bottom: 0vh; width: 80vh; z-index: 1"
        src="./s7/pc-style_1.png"
          :alt="`${info.caseName}style_1`"
      />
      <img loading="lazy"
        data-aos="fade"
        data-aos-delay="500"
        class="absolute"
        style="right: 0vh; bottom: 0vh; width: 83vh"
        src="./s7/pc-style_2.png"
          :alt="`${info.caseName}style_2`"
      />

      <div class="flex relative">
        <img loading="lazy"
          class="absolute blink"
          style="top: 21vh; right: 82vh; width: 10vh"
          src="./s7/pc-star.svg"
          :alt="`${info.caseName}star`"
        />
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          class="flex flex-col"
          style="padding-top: 3%; padding-left: 3.5%"
        >
        <h3 class="mb-4 title">國家級安全防護<br>　職人鍛造<span>頂奢耐震</span><b>。</b></h3>
          <p
            class="flex items-center text-primary-400 text-1.4 font-weight-900 tracking-wide font-secondary"
          >
            <span>Safety Structure</span>
            <span class="s7-line mx-3" style="width: 2vw"></span>
            <span style="font-family: Noto Serif CJK TC, serif"
              >豪宅規格。安全。獨樹一格</span
            >
          </p>
          <h4 class="flex relative" style="margin-top: 7%">
            <span class="text-primary-500 text-1.6">ＳＣ鋼骨結構</span>
            <span class="text-primary-300 text-1.6 mx-1">。</span>
            <span class="text-primary-500 text-1.6"
              >岩層基座 安全升級</span
            >
          </h4>
          <div class="text-primary-500 text-1 leading-loose text-left mb-7">
            <p>岳泰建設不惜動用最高建築成本，只為給您雙重保護</p>
          </div>
          <div class="text-1 relative" style="margin-bottom: 8%">
            <span
              class="text-primary-500 text-white py-1 px-3 absolute"
              style="background-color: #62cbc9; top: -1.5vw; left: -0.5vw"
              >保護一</span
            >
            <div
              class="border border-solid text-primary-500 py-4 pl-4 text-left ml-4"
              style="
                border-color: #62cbc9;

                width: 35vw;
              "
            >
              基地座落岩層，承載性佳，建物立地更安穩，住的更安心
            </div>
          </div>
          <div class="text-1 relative">
            <span
              class="text-primary-500 text-white py-1 px-3 absolute"
              style="background-color: #62cbc9; top: -1.5vw; left: -0.5vw"
              >保護二</span
            >
            <div
              class="border border-solid text-primary-500 py-4 pl-4 text-left ml-4"
              style="border-color: #62cbc9; width: 35vw"
            >
              以豪宅規格打造紅樹林稀有的耐震SC鋼骨+CFT結構，耐震可達六級
            </div>
          </div>
          <span
            class="text-primary-200 text-2 font-weight-900 text-left tracking-wide"
            style="margin-top: 4%; z-index: 2"
            >因為稀有，所以珍貴；因為珍貴，更是獨特</span
          >
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="mb-s7-bg">
      <div data-aos="fade-up" data-aos-delay="400">
        <span
          class="text-primary-200 font-weight-900 font-secondary"
          style="font-size: 10.7vw; opacity: 0.23"
          >Safety Structure</span
        >
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="400"
        class="flex flex-col"
        style="margin-left: 5vw"
      >
      <h3 class="mb-2 title">國家級安全防護<br>　職人鍛造<span>頂奢耐震</span><b>。</b></h3>
        <h4 class="flex relative my-4">
          <span class="text-primary-500 text-4.5">ＳＣ鋼骨結構</span>
          <span class="text-primary-300 text-4.5 mx-1">。</span>
          <span class="text-primary-500 text-4.5">岩層基座 安全升級</span>
        </h4>
        <div class="flex items-center text-3.5">
          <span
            class="text-white px-2 py-2 mr-2 text-center"
            style="background-color: #62cbc9; width: 17vw"
            >保護一</span
          >
          <span class="text-primary-500 text-left" style="width: 68vw"
            >基地座落岩層，承載性佳，建物立地更安穩，住的更安心</span
          >
        </div>
        <div class="flex items-center text-3.5 my-3">
          <span
            class="text-white px-2 py-2 mr-2 text-center"
            style="background-color: #62cbc9; width: 17vw"
            >保護二</span
          >
          <span class="text-primary-500 text-left" style="width: 68vw"
            >以豪宅規格打造紅樹林稀有的耐震SC鋼骨+CFT結構，耐震可達六級</span
          >
        </div>
      </div>
      <img loading="lazy"
        data-aos="fade"
        data-aos-delay="400"
        class="w-full"
        src="./s7/pc-img.jpg"
          :alt="`${info.caseName}img`"
      />
    </div>
  </article>
</template>
<style lang="scss">
@import '@/assets/style/function.scss';
@import '@/assets/style/animate.scss';
.blink {
  @include animate(fadeIn, 0.8s, 0);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  pointer-events: none;
}
.title{
  text-align: left;
  font-size: size(60);
  letter-spacing: .04em;
  line-height: 1.25;
  white-space: nowrap;
  span{
    font-weight: 900;margin: 0 0 0 0.3em;
    font-size: 1.01em;
  }
  b{font-weight: 900;position: relative;top:.3em;margin:0 -.08em 0 -.26em;}
}
/* 螢幕尺寸標準 */
.s7-line {
  background-color: #6e6e6e;
  height: 1px;
}
// Mobile

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
.title{
  font-size: size-m(31.5);
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import info from "@/info";
import { isMobile } from '@/utils'

export default {
  name: 'section7',
  data() {
    return {
      info,
      isMobile,
    }
  },

  methods: {},

  mounted() {},

  created() {},
}
</script>
