<template>
  <article class="section1">
    <h3 v-html="`${info.caseName}-堅持初心，我獨樹一格`"></h3>
    <!-- PC -->
    <div v-if="!isMobile" class="s1-bg" style="height: 100vh">

      <div
        class="flex flex-col textShowall"
        style="padding-left: 20%; padding-top: 9%"
      >
        <div class="flex justify-center">
          <img loading="lazy"
            class="mr-7 pt-3"
            style="width: 4vw; height: 4vw"
            src="./s1/pc-star.svg"
          :alt="`${info.caseName}_pc-star`"
          />
          <div
            class="text-dark text-1.6 text-left leading-loose"
            style="width: 40%"
          >
            <p class="fadeIn textShow0">
              不忘初心，是為了汲取更大的能量
            </p>
            <p class="fadeIn textShow1">
              回歸最自然純粹的生活，向更高的夢想邁進
            </p>
            <p class="fadeIn textShow2">
              初心生活，不同凡響 Less is More
            </p>
          </div>
        </div>
        <div
          class="flex justify-center fadeIn textShow3 title-new"
          style="margin-top: 9%;"
        >
          <img loading="lazy" style="width: 75vh" src="./s1/pc-title.svg" 
          :alt="`${info.caseName}_title`" />
        </div>
      </div>
      <div class="isChange" style="padding-left: 56vw; padding-top: 16vw;">
        <img loading="lazy"
          class="scaleUp_fadeIn"
          src="./s1/pc-logo.svg"
          style="width: 20vw"
          :alt="`${info.caseName}_logo`"
        />
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="h-screen mb-s1-bg">
      <div
        class="flex flex-col items-center textShowall"
        style="padding-top: 15%"
      >
        <div class="text-dark text-4 text-left leading-loose mb-5 mt-10">
          <p class="textShow0">不忘初心，是為了汲取更大的能量</p>
          <p class="textShow1">
            回歸最自然純粹的生活，向更高的夢想邁進
          </p>
          <p class="textShow2">初心生活，不同凡響 Less is More</p>
        </div>
        <img
          class=" textShow3"
          style="width: 76vw"
          src="./s1/pc-title.svg"
          :alt="`${info.caseName}_title`"
        />
      </div>
      <!--  -->
      <div class="isChange flex justify-center h-5/10vh  mt-10">
        <img loading="lazy" class="w-5/10vw scaleUp_fadeIn" src="./s1/pc-logo.svg" 
          :alt="`${info.caseName}_logo`" />
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped>
.section1 > h3{display: none;}
/* 螢幕尺寸標準 */
.s1-bg {
  background-image: url('./s1/pc-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.text-dark{
  color: #333333;
}
.title-new{
  bottom:3% !important;
  right:3% !important;
  position: absolute;
}

.textShow0,
.textShow1,
.textShow2,
.textShow3{
  animation:op1 1s forwards;
    opacity: 0;
}
.textShowall{
  animation:op1 1s reverse both;
    opacity: 0;
}
.isChange{
  position: absolute;left: 0;top: 0;right: 0;
  animation:op1 1s forwards;
    opacity: 0;
}
.textShow0{animation-delay:1s}
.textShow1{animation-delay:2s}
.textShow2{animation-delay:3s}
.textShow3{animation-delay:4s}
.textShowall{animation-delay:6s}
.isChange{animation-delay:7s}

@keyframes op1{
  to {
    opacity: 1;
  }
}



/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .mb-s1-bg {
    background-image: url('./s1/mb-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import info from "@/info";
import { isMobile } from '@/utils'

export default {
  name: 'section1',
  data() {
    return {
      info,
      isMobile,
      isEffect: false,
    }
  },
  mounted() {
  },
}
</script>
