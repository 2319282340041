<template>
  <article id="section2" class="section2">
    <!-- PC -->
    <div v-if="!isMobile" class="s2-bg">
      <div class="flex justify-around items-center">
        <div class="flex flex-col items-center">
          <div class="relative" style="width: 3vw; height: 3vw">
            <img loading="lazy"
              class="absolute h-full blink"
              style="top: 1.3vw; left: 7vw"
              src="./s2/pc-star.svg"
          :alt="`${info.caseName}star`"
            />
          </div>
          <span
            data-aos="fade-up"
            data-aos-delay="500"
            class="text-primary-100 text-2.2"
            >岳泰峰碩系列影片</span
          >
          <img loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
            class="mb-5"
            style="width: 16.5vw; height: 2vw"
            src="./s2/pc-line.svg"
          :alt="`${info.caseName}line`"
          />
          <div
            data-aos="fade-up"
            data-aos-delay="500"
            class="flex flex-col text-1.5"
          >
            <div
              :class="activeIndex === 0 ? 'text-white' : ''"
              class="flex my-5 cursor-pointer text-primary-100 hover:text-white"
              @click="activeIndex = 0"
            >
              <img loading="lazy"
                v-if="activeIndex === 0"
                class="mr-5"
                style="width: 2vw"
                src="./s2/pc-icon_active.svg"
          :alt="`${info.caseName}_icon_active`"
              />

              <img loading="lazy"
                v-else
                class="mr-5"
                style="width: 2vw"
                src="./s2/pc-icon.svg"
          :alt="`${info.caseName}icon`"
              />
              <span class="video-title">『岳泰峰碩』在紅樹林 享受微旅生活</span>
            </div>
           <!-- 
            <div
              :class="activeIndex === 1 ? 'text-white' : ''"
              class="flex my-5 cursor-pointer text-primary-100 hover:text-white"
              @click="activeIndex = 1"
            >
              <img loading="lazy"
                v-if="activeIndex === 1"
                class="mr-5"
                style="width: 2vw"
                src="./s2/pc-icon_active.svg"
          :alt="`${info.caseName}icon_active`"
              />

              <img loading="lazy"
                v-else
                class="mr-5"
                style="width: 2vw"
                src="./s2/pc-icon.svg"
          :alt="`${info.caseName}icon`"
              />
              <span class="">優質景觀公設</span>
            </div>
            <div
              :class="activeIndex === 2 ? 'text-white' : ''"
              class="flex my-5 cursor-pointer text-primary-100 hover:text-white"
              @click="activeIndex = 2"
            >
              <img loading="lazy"
                v-if="activeIndex === 2"
                class="mr-5"
                style="width: 2vw"
                src="./s2/pc-icon_active.svg"
          :alt="`${info.caseName}icon_active`"
              />

              <img loading="lazy"
                v-else
                class="mr-5"
                style="width: 2vw"
                src="./s2/pc-icon.svg"
          :alt="`${info.caseName}icon`"
              />
              <span class="">高品質規劃</span>
            </div>
            <div
              :class="activeIndex === 3 ? 'text-white' : ''"
              class="flex my-5 cursor-pointer text-primary-100 hover:text-white"
              @click="activeIndex = 3"
            >
              <img loading="lazy"
                v-if="activeIndex === 3"
                class="mr-5"
                style="width: 2vw"
                src="./s2/pc-icon_active.svg"
          :alt="`${info.caseName}icon_active`"
              />

              <img loading="lazy"
                v-else
                class="mr-5"
                style="width: 2vw"
                src="./s2/pc-icon.svg"
          :alt="`${info.caseName}icon`"
              />
              <span class="">絕美戶行大公開</span>
            </div>
            -->
          </div>
          <img loading="lazy"
            data-aos="fade-up"
            data-aos-delay="500"
            class="mt-5"
            style="width: 16.5vw; height: 2vw"
            src="./s2/pc-line.svg"
          :alt="`${info.caseName}line`"
          />
        </div>
        <div class="relative">
          <img loading="lazy"
            style="width: 58vw; margin-top: 6%"
            src="./s2/pc-video_line.svg"
          :alt="`${info.caseName}video_line`"
          />
          <div v-for="(slide, i) in slides" :key="i">
            <iframe
              v-if="activeIndex === i"
              class="absolute"
              width="58vw"
              height="90%"
              style="width: 58vw; top: 4.5vw; left: 1vw"
              :src="slide.link"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </div>
          <img loading="lazy"
            class="absolute blink"
            style="width: 3.5vw; height: 3.5vw; bottom: -1.75vw; right: -3.75vw"
            src="./s1/pc-star.svg"
          :alt="`${info.caseName}star`"
          />
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="mb-s2-bg relative">
      <img loading="lazy"
        class="absolute z-50 blink"
        style="width: 13vw; right: 11vw; top: 17vw"
        src="./s2/pc-star.svg"
          :alt="`${info.caseName}star`"
      />
      <div class="flex flex-col">
        <span
          data-aos="fade-up"
          data-aos-delay="500"
          class="text-primary-100 text-8 my-8"
          >岳泰峰碩系列影片</span
        >
        <div id="swiper-container" class="w-full">
          <!-- <swiper
              data-aos="fade"
              data-aos-delay="400"
              :options="swiperOptions"
              ref="swiperSection2"
              @slideChangeTransitionStart="slideChange"
            >
              <swiper-slide
                class="slide"
                v-for="(slide, i) in slides"
                v-bind:key="i"
              >
                <iframe
                  width="100%"
                  height="100%"
                  :src="slide.link"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                ></iframe>
              </swiper-slide>
            </swiper> -->
          <!--  -->
          <VueSlickCarousel
            ref="carousel"
            :arrows="true"
            :dots="false"
            :autoplay="true"
            :autoplaySpeed="5000"
            :fade="true"
            @afterChange="afterChange"
          >
            <div v-for="(slide, i) in slides" :key="i">
              <iframe
                width="100%"
                height="250vh"
                :src="slide.link"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </div>
          </VueSlickCarousel>

          <!--  -->
          <div
            class="flex justify-between"
            style="width: 100vw; padding: 0 6%; margin: 1.2rem 0"
          >
            <img loading="lazy"
              @click="prev()"
              class="w-8 cursor-pointer"
              src="./s2/mb-arrow_l.svg"
          :alt="`${info.caseName}arrow_l`"
            />
            <span class="text-white self-center text-6">
              {{ slides[activeIndex].title }}
            </span>
            <img loading="lazy"
              @click="next()"
              class="w-8 cursor-pointer"
              src="./s2/mb-arrow_r.svg"
          :alt="`${info.caseName}arrow_r`"
            />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<style lang="scss" scoped>
@import '@/assets/style/animate.scss';
.blink {
  @include animate(fadeIn, 0.8s, 0);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  pointer-events: none;
}
/* 螢幕尺寸標準 */
.s2-bg {
  height: 95vh;
  background-image: url('./s2/pc-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
.swiper-container {
  height: 93%;
  width: 100%;
  margin: 0;
  .slide {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: 50%;
    .caption {
      position: absolute;
      z-index: 1;
      color: #fff;
      right: 2vw;
      bottom: 0.8vw;
      font-size: 1.2vw;
      font-weight: bold;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
  .swiper-button-prev {
    // left: size(500);
    left: 0;
    bottom: 0;
    width: size(80);
    height: size(80);
    color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
  .swiper-button-next {
    right: 0;
    color: #fff;
    width: size(80);
    height: size(80);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
}
// Mobile

/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  .mb-s2-bg {
    background-image: url('./s2/mb-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .text-6{
    font-size: 20px !important;
    margin-bottom: 30px;
  }
  .cursor-pointer{
    display: none;
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1440px) and (max-width: 1980px) {
  .s2-bg {
    height: 85vh;
  }
}
@media only screen and (min-width: 7200px) and (max-width: 1280px) {
  .s2-bg {
    height: 90vh;
  }
}
</style>

<script>
// @ is an alias to /src
import info from "@/info";
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'section2',
  components: {
    Swiper,
    SwiperSlide,
    VueSlickCarousel,
  },
  directives: {
    swiper: directive,
  },

  data() {
    return {
      info,
      isMobile,
      activeIndex: 0,
      slides: [
        {
          img: require('./s2/pc-video_img.jpg'),
          caption: '捷運先嗇宮站',
          title: '『岳泰峰碩』在紅樹林 享受微旅生活',
          link: 'https://www.youtube.com/embed/IfIoArBk3ok',
        },
      ],
      swiperOptions: {
        effect: 'fade',
        speed: 1200,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section2 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
    }
  },
  methods: {
    slideChange() {
      this.activeIndex = this.$refs.swiperSection2.$swiper.realIndex
    },
    prev() {
      // this.$refs.swiperSection2.$swiper.slidePrev()
      this.$refs.carousel.prev()
    },
    next() {
      // this.$refs.swiperSection2.$swiper.slideNext()
      this.$refs.carousel.next()
    },
    afterChange(index) {
      this.activeIndex = index
    },
  },
}
</script>
