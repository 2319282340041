<template>
  <article id="section9" class="section9">
    <div v-if="!isMobile" class="s9-bg relative" style="height: 85vh">
      <img
        loading="lazy"
        class="absolute blink"
        style="top: 30vh; left: 4vw; width: 8vh"
        src="./s9/pc-star.svg"
          :alt="`${info.caseName}star`"
      />
      <img
        loading="lazy"
        class="absolute blink"
        style="top: 36vh; left: 86vw; width: 8vh"
        src="./s9/pc-star.svg"
          :alt="`${info.caseName}star`"
      />
      <div class="flex flex-col" style="padding-top: 3%; padding-left: 4%">
        <h3 class="mb-3 title text-white" data-aos="fade-up"
        data-aos-delay="500">豪宅團隊 聯手擘劃<span>地標美學</span><b>。</b></h3>

        <p
          data-aos="fade-up"
          data-aos-delay="500"
          class="flex font-secondary items-center"
        >
          <span class="text-primary-100 text-2 font-weight-900 mr-5"
            >Professional Team</span
          >
          <span class="text-white text-1.3">大師團隊。專業。獨樹一格</span>
        </p>
        <div
          class="flex justify-around items-end w-1/4 relative"
          style="margin-top: 5%; margin-left: 3%"
        >
          <h4
            class="flex flex-col cursor-pointer text-white transform transition duration-500 hover:scale-110"
            style="width: 1vw"
            @click="openDialog(0)"
          >
            <img
              loading="lazy"
              class="mb-2 blink"
              src="./s9/pc-icon_active.svg"
          :alt="`${info.caseName}icon_active`"
            />
            <span class="text-1">岳泰建設</span>
          </h4>
          <h4
            class="flex flex-col cursor-pointer text-white transform transition duration-500 hover:scale-110"
            style="width: 1vw"
            @click="openDialog(1)"
          >
            <img
              loading="lazy"
              class="mb-2 blink"
              src="./s9/pc-icon_active.svg"
          :alt="`${info.caseName}icon_active`"
            />
            <span class="text-1">三門聯合建築師事務所</span>
          </h4>
          <h4
            class="flex flex-col cursor-pointer text-white transform transition duration-500 hover:scale-110"
            style="width: 1vw; padding-bottom: 15%"
            @click="openDialog(2)"
          >
            <img
              loading="lazy"
              class="mb-2 blink"
              src="./s9/pc-icon_active.svg"
          :alt="`${info.caseName}icon_active`"
            />
            <span class="text-1">聯邦工程</span>
          </h4>
          <img
            loading="lazy"
            data-aos="fade"
            data-aos-delay="500"
            class="absolute"
            style="width: 10vw; left: -7vw; top: 11vw"
            src="./s9/pc-line_1.svg"
          :alt="`${info.caseName}line_1`"
          />
          <img
            loading="lazy"
            data-aos="fade"
            data-aos-delay="500"
            class="absolute"
            style="width: 6.2vw; left: 5vw; top: 13.8vw"
            src="./s9/pc-line_2.svg"
          :alt="`${info.caseName}line_2`"
          />
          <img
            loading="lazy"
            data-aos="fade"
            data-aos-delay="500"
            class="absolute"
            style="width: 5.8vw; left: 13vw; top: 11.5vw"
            src="./s9/pc-line_3.svg"
          :alt="`${info.caseName}line_3`"
          />
          <img
            loading="lazy"
            data-aos="fade"
            data-aos-delay="500"
            class="absolute"
            style="width: 22vw; left: 21vw; top: 11.5vw"
            src="./s9/pc-line_4.svg"
          :alt="`${info.caseName}line_4`"
          />
        </div>
        <h4
          data-aos="fade-up"
          data-aos-delay="500"
          class="flex"
          style="margin-top: 5%"
        >
          <span class="text-white text-1.8">岳泰建設</span>
          <img
            loading="lazy"
            class="mx-2"
            style="width: 1.5vw"
            src="./s9/pc-x.svg"
          :alt="`${info.caseName}X`"
          />
          <span class="text-white text-1.8">三門建築</span>
          <img
            loading="lazy"
            class="mx-2"
            style="width: 1.5vw"
            src="./s9/pc-x.svg"
          :alt="`${info.caseName}X`"
          />
          <span class="text-white text-1.8 mr-2">聯邦工程 </span>
          <span class="text-primary-300 text-1.8">三強名門鉅作 </span>
        </h4>
      </div>
    </div>
    <!-- Mobile -->
    <div v-if="isMobile" class="mb-s9-bg" style="height: 100vh">
      <div data-aos="fade-up" data-aos-delay="400">
        <span
          class="text-primary-200 font-weight-900 font-secondary"
          style="font-size: 8.5vw; opacity: 0.23"
          >Professional Team</span
        >
      </div>
        <h3 class="mb-2 title text-white" data-aos="fade-up"
        data-aos-delay="400">豪宅團隊 聯手擘劃<span>地標美學</span><b>。</b></h3>

      <div
        class="flex justify-around items-end w-full mt-5 relative overflow-hidden"
      >
        <h4
          class="flex flex-col items-center cursor-pointer text-white ml-7"
          @click="openDialog(0)"
        >
          <img
            loading="lazy"
            class="mb-2 blink"
            style="width: 3vw"
            src="./s9/pc-icon_active.svg"
          :alt="`${info.caseName}icon_active`"
          />
          <span class="text-4 leading-5 mb-10" style="width: 4vw"
            >岳泰建設</span
          >
        </h4>
        <h4
          class="flex flex-col items-center cursor-pointer text-white"
          @click="openDialog(1)"
        >
          <img
            loading="lazy"
            class="mb-2 blink"
            style="width: 3vw"
            src="./s9/pc-icon_active.svg"
          :alt="`${info.caseName}icon_active`"
          />
          <span class="text-4 leading-5" style="width: 4vw"
            >三門聯合建築師事務所</span
          >
        </h4>
        <h4
          class="flex flex-col items-center cursor-pointer text-white mr-7"
          @click="openDialog(2)"
        >
          <img
            loading="lazy"
            class="mb-2 blink"
            style="width: 3vw"
            src="./s9/pc-icon_active.svg"
          :alt="`${info.caseName}icon_active`"
          />
          <span class="text-4 leading-5 mb-20" style="width: 4vw"
            >聯邦工程</span
          >
        </h4>
        <img
          loading="lazy"
          data-aos="fade"
          data-aos-delay="400"
          class="absolute"
          style="width: 44vw; left: -27vw; top: 16vw"
          src="./s9/pc-line_1.svg"
          :alt="`${info.caseName}line_1`"
        />
        <img
          loading="lazy"
          data-aos="fade"
          data-aos-delay="400"
          class="absolute"
          style="width: 22vw; left: 24vw; top: 30vw"
          src="./s9/pc-line_2.svg"
          :alt="`${info.caseName}line_2`"
        />
        <img
          loading="lazy"
          data-aos="fade"
          data-aos-delay="400"
          class="absolute"
          style="width: 21vw; left: 54vw; top: 20.5vw"
          src="./s9/pc-line_3.svg"
          :alt="`${info.caseName}line_3`"
        />
        <img
          loading="lazy"
          data-aos="fade"
          data-aos-delay="400"
          class="absolute"
          style="width: 75vw; left: 83vw; top: 20.5vw"
          src="./s9/pc-line_4.svg"
          :alt="`${info.caseName}line_4`"
        />
      </div>

      <p
        data-aos="fade-up"
        data-aos-delay="400"
        class="text-primary-300 text-6"
      >
        三強名門鉅作
      </p>
    </div>
    <!-- Dialog_0 -->
    <el-dialog
      title
      :visible="isDialogShow_0"
      :width="isMobile ? '90%' : '95vw'"
      :modal-append-to-body="false"
      :show-close="false"
      :lock-scroll="true"
    >
      <div
        v-if="!isMobile"
        class="w-full h-full border border-solid border-white relative"
        style="background: #68838c"
      >
        <img
          loading="lazy"
          @click="closeDialog()"
          class="cursor-pointer absolute right-0 top-0"
          style="width: 3vw"
          src="./s9/popup/close.svg"
          :alt="`${info.caseName}close`"
        />
        <img
          loading="lazy"
          class="absolute right-2 bottom-12"
          src="./s9/popup/en_style.svg"
          style="width: 2%"
          :alt="`${info.caseName}en_style`"
        />
        <div class="flex h-full">
          <div style="width: 35%" class="relative bg-dialog0-img">
            <img
              loading="lazy"
              class="absolute"
              style="width: 15%; left: 2rem; top: 0"
              src="./s9/popup/01/title.svg"
          :alt="`${info.caseName}title`"
            />
            <img
              loading="lazy"
              class="absolute blink"
              style="width: 13%; top: 29%; left: 56%"
              src="./s9/popup/star.svg"
          :alt="`${info.caseName}star`"
            />
            <div
              id="text_area"
              class="absolute text-left text-white"
              style="
                font-size: 1.1vw;
                opacity: 0.8;
                top: 5%;
                left: 26%;
                line-height: 2;
              "
            >
              <p>打造不同凡響的建築</p>
              <p>我們希望改變城市的建築高度</p>
              <p>也改變住戶的生活高度</p>
            </div>
          </div>
          <!-- Swiper Area -->
          <div style="width: 65%; padding-top: 5%; padding-right: 5%" class="">
            <div id="swiper-container" class="w-full h-full relative">
              <div
                class="absolute dialog-blank"
                style="top: -1vw; right: -1vw"
              ></div>

              <div
                id="swiper-container"
                style="width: 100%"
                class="h-full relative"
              >
                <VueSlickCarousel
                  ref="carousel"
                  :arrows="true"
                  :dots="false"
                  :autoplay="true"
                  :autoplaySpeed="5000"
                  :fade="true"
                  :adaptiveHeight="true"
                >
                  <img
                    loading="lazy"
                    v-for="(slide, i) in slides0"
                    :key="i"
                    :src="slide.img"
          :alt="`${info.caseName}_img`"
                  />
                </VueSlickCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isMobile" class="relative h-full flex flex-col">
        <img
          loading="lazy"
          class="absolute"
          style="width: 12vw; top: 40vw; right: 7vw"
          src="./s9/popup/star.svg"
          :alt="`${info.caseName}star`"
        />
        <img
          loading="lazy"
          class="absolute"
          style="right: 5vw; bottom: 3vw; width: 4vw"
          src="./s9/popup/en_style.svg"
          :alt="`${info.caseName}en_style`"
        />
        <img
          loading="lazy"
          @click="closeDialog()"
          class="absolute"
          style="right: 0; top: 0; width: 14vw"
          src="./s9/popup/close.svg"
          :alt="`${info.caseName}close`"
        />
        <div class="flex flex-col">
          <div class="flex items-center mt-6 ml-5">
            <span class="text-white text-8">岳泰建設</span>
            <div class="mb-line mx-1"></div>
          </div>
          <p class="text-white text-5.5 text-left mb-4 ml-5">執行長羅樹炎</p>
          <div class="text-white text-4 text-left ml-5 leading-relaxed">
            <p>打造不同凡響的建築</p>
            <p>我們希望改變城市的建築高度</p>
            <p>也改變住戶的生活高度</p>
          </div>
        </div>
        <!-- Swipeer -->
        <div
          style="padding-top: 3%; margin-left: 5.5%; height: 47vw; width: 90%"
        >
          <div id="swiper-container" class="w-full h-full relative">
            <VueSlickCarousel
              ref="carousel"
              :arrows="true"
              :dots="false"
              :autoplay="true"
              :autoplaySpeed="5000"
              :fade="true"
            >
              <div v-for="(slide, i) in slides0" :key="i">
                <div
                  :style="{
                    backgroundImage: `url(${slide.img})`,
                    height: '48vw',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                  }"
                ></div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
        <div class="mt-auto">
          <img loading="lazy" src="./s9/popup/01/bg.png" 
          :alt="`${info.caseName}bg`" />
        </div>
      </div>
    </el-dialog>
    <!-- Dialog_1 -->
    <el-dialog
      title
      :visible="isDialogShow_1"
      :width="isMobile ? '90%' : '95vw'"
      :modal-append-to-body="false"
      :show-close="false"
      :lock-scroll="true"
    >
      <div
        v-if="!isMobile"
        class="w-full h-full border border-solid border-white relative"
        style="background: #68838c"
      >
        <img
          loading="lazy"
          @click="closeDialog()"
          class="cursor-pointer absolute right-0 top-0"
          style="width: 3vw"
          src="./s9/popup/close.svg"
          :alt="`${info.caseName}close`"
        />
        <img
          loading="lazy"
          class="absolute right-2 bottom-12"
          src="./s9/popup/en_style.svg"
          style="width: 2%"
          :alt="`${info.caseName}en_style`"
        />
        <div class="flex h-full">
          <div style="width: 35%" class="relative bg-dialog1-img">
            <img
              loading="lazy"
              class="absolute"
              style="width: 15%; left: 2rem; top: 0"
              src="./s9/popup/02/title.svg"
          :alt="`${info.caseName}title`"
            />
            <img
              loading="lazy"
              class="absolute blink"
              style="width: 13%; top: 73%; left: 83%"
              src="./s9/popup/star.svg"
          :alt="`${info.caseName}star`"
            />
            <div
              id="text_area"
              class="absolute text-left text-white"
              style="
                font-size: 1.1vw;
                opacity: 0.8;
                top: 5%;
                left: 27%;
                line-height: 2;
              "
            >
              <p>北市豪宅御用的三門聯合建築師事務</p>
              <p>主持建築師白省三大師</p>
              <p>引領40年建築時尚天際線</p>
              <p>再創紅樹林新地標</p>
            </div>
          </div>
          <!-- Swiper Area -->
          <div style="width: 65%; padding-top: 5%; padding-right: 5%" class="">
            <div id="swiper-container" class="w-full relative">
              <div
                class="absolute dialog-blank"
                style="top: -1vw; right: -1vw"
              />
              <VueSlickCarousel
                ref="carousel"
                :arrows="true"
                :dots="false"
                :autoplay="true"
                :autoplaySpeed="5000"
                :fade="true"
                :adaptiveHeight="true"
              >
                <img
                  loading="lazy"
                  v-for="(slide, i) in slides1"
                  :key="i"
                  :src="slide.img"
          :alt="`${info.caseName}img`"
                />
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isMobile" class="relative h-full flex flex-col">
        <img
          loading="lazy"
          class="absolute"
          style="width: 12vw; top: 40vw; right: 7vw"
          src="./s9/popup/star.svg"
          :alt="`${info.caseName}star`"
        />
        <img
          loading="lazy"
          class="absolute"
          style="right: 5vw; bottom: 3vw; width: 4.2vw"
          src="./s9/popup/en_style.svg"
          :alt="`${info.caseName}en_style`"
        />
        <img
          loading="lazy"
          @click="closeDialog()"
          class="absolute"
          style="right: 0; top: 0; width: 14vw"
          src="./s9/popup/close.svg"
          :alt="`${info.caseName}close`"
        />
        <div class="flex flex-col">
          <div class="flex items-center mt-6 ml-5">
            <span class="text-white text-8">三門建築</span>
            <div class="mb-line mx-1"></div>
          </div>
          <p class="text-white text-5.5 text-left mb-4 ml-5">白省三建築師</p>
          <div class="text-white text-4 text-left ml-5 leading-relaxed">
            <p>北市豪宅御用的三門聯合建築師事務所</p>
            <p>主持建築師白省三大師</p>
            <p>引領40年建築時尚天際線</p>
            <p>再創紅樹林新地標</p>
          </div>
        </div>
        <!-- Swipeer -->
        <div
          style="padding-top: 3%; margin-left: 5.5%; height: 47vw; width: 90%"
          class=""
        >
          <div id="swiper-container" class="w-full h-full relative">
            <VueSlickCarousel
              ref="carousel"
              :arrows="true"
              :dots="false"
              :autoplay="true"
              :autoplaySpeed="5000"
              :fade="true"
            >
              <div v-for="(slide, i) in slides1" :key="i">
                <div
                  :style="{
                    backgroundImage: `url(${slide.img})`,
                    height: '48vw',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                  }"
                ></div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
        <div class="mt-auto">
          <img
            loading="lazy"
            style="width: 75%"
            src="./s9/popup/02/bg.png"
          :alt="`${info.caseName}bg`"
          />
        </div>
      </div>
    </el-dialog>
    <!-- Dialog_2 -->
    <el-dialog
      title
      :visible="isDialogShow_2"
      :width="isMobile ? '90%' : '95vw'"
      :modal-append-to-body="false"
      :show-close="false"
      :lock-scroll="true"
    >
      <div
        v-if="!isMobile"
        class="w-full h-full border border-solid border-white relative"
        style="background: #68838c"
      >
        <img
          loading="lazy"
          @click="closeDialog()"
          class="cursor-pointer absolute right-0 top-0"
          style="width: 3vw"
          src="./s9/popup/close.svg"
          :alt="`${info.caseName}close`"
        />
        <img
          loading="lazy"
          class="absolute right-2 bottom-12"
          src="./s9/popup/en_style.svg"
          style="width: 2%"
          :alt="`${info.caseName}en_style`"
        />
        <div class="flex h-full">
          <div style="width: 35%" class="relative bg-dialog2-img">
            <img
              loading="lazy"
              class="absolute"
              style="width: 15%; left: 2rem; top: 0"
              src="./s9/popup/03/title.svg"
          :alt="`${info.caseName}title`"
            />
            <img
              loading="lazy"
              class="absolute blink"
              style="width: 13%; top: 27%; left: 83%"
              src="./s9/popup/star.svg"
          :alt="`${info.caseName}star`"
            />
            <div
              id="text_area"
              class="absolute text-left text-white"
              style="
                font-size: 1.1vw;
                opacity: 0.8;
                top: 30%;
                left: 23%;
                line-height: 2;
              "
            >
              <p>28年耀眼成就</p>
              <p>安鑄巨擘的專業工程推手</p>
              <p>我們打造的是安全、百年傳家的好宅</p>
            </div>
          </div>
          <!-- Swiper Area -->
          <div style="width: 65%; padding-top: 5%; padding-right: 5%" class="">
            <div id="swiper-container" class="w-full h-full relative">
              <div
                class="absolute dialog-blank"
                style="top: -1vw; right: -1vw"
              ></div>
              <VueSlickCarousel
                ref="carousel"
                :arrows="true"
                :dots="false"
                :autoplay="true"
                :autoplaySpeed="5000"
                :fade="true"
                :adaptiveHeight="true"
              >
                <img
                  loading="lazy"
                  v-for="(slide, i) in slides2"
                  :key="i"
                  :src="slide.img"
          :alt="`${info.caseName}img`"
                />
              </VueSlickCarousel>
            </div>
          </div>
        </div>
      </div>
      <div v-if="isMobile" class="relative h-full flex flex-col">
        <img
          loading="lazy"
          class="absolute"
          style="width: 12vw; top: 36vw; right: 7vw"
          src="./s9/popup/star.svg"
          :alt="`${info.caseName}star`"
        />
        <img
          loading="lazy"
          class="absolute"
          style="right: 5vw; bottom: 3vw; width: 3.7vw"
          src="./s9/popup/en_style.svg"
          :alt="`${info.caseName}en_style`"
        />
        <img
          loading="lazy"
          @click="closeDialog()"
          class="absolute"
          style="right: 0; top: 0; width: 14vw"
          src="./s9/popup/close.svg"
          :alt="`${info.caseName}close`"
        />
        <div class="flex flex-col">
          <div class="flex items-center mt-6 ml-5">
            <span class="text-white text-8">聯邦工程</span>
            <div class="mb-line mx-1"></div>
          </div>
          <div class="text-white text-4 text-left ml-5 mt-10 leading-relaxed">
            <p>28年耀眼成就</p>
            <p>安鑄巨擘的專業工程推手</p>
            <p>我們打造的是安全、百年傳家的好宅</p>
          </div>
        </div>
        <!-- Swipeer -->
        <div
          style="padding-top: 3%; margin-left: 5.5%; height: 47vw; width: 90%"
        >
          <div id="swiper-container" class="w-full h-full relative">
            <div class="absolute" style="top: -1vw; right: -1vw"></div>
            <VueSlickCarousel
              ref="carousel"
              :arrows="true"
              :dots="false"
              :autoplay="true"
              :autoplaySpeed="5000"
              :fade="true"
            >
              <div v-for="(slide, i) in slides2" :key="i">
                <div
                  :style="{
                    backgroundImage: `url(${slide.img})`,
                    height: '48vw',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    position: 'relative',
                  }"
                ></div>
              </div>
            </VueSlickCarousel>
          </div>
        </div>
        <img loading="lazy" class="mt-auto" src="./s9/popup/03/bg.png" 
          :alt="`${info.caseName}bg`" />
      </div>
    </el-dialog>
  </article>
</template>
<style lang="scss" scoped>
@import '@/assets/style/function.scss';
@import '@/assets/style/animate.scss';
::v-deep .slick-prev {
  z-index: 9999;
  left: 9px;
  background-image: url('./s9/popup/arrow_l.svg');
  background-repeat: no-repeat;
  width: 3vw;
  height: 3vw;

  &::before {
    content: '';
  }
}
::v-deep .slick-next {
  z-index: 9999;
  right: 9px;
  background-image: url('./s9/popup/arrow_r.svg');
  background-repeat: no-repeat;
  width: 3vw;
  height: 3vw;

  &::before {
    content: '';
  }
}
.s9-bg {
  background-image: url('./s9/pc-bg.jpg');
  background-size: cover;
  background-position: 70% 100%;
}
.blink {
  @include animate(fadeIn, 0.8s, 0);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  pointer-events: none;
}
.title{
  text-align: left;
  font-size: size(58);
  letter-spacing: .03em;
  white-space: nowrap;
  margin: 0 0 0 -.06em;
  span{
    font-weight: 900;margin: 0 0 0 0.3em;
    font-size: 1.01em;
  }
  b{font-weight: 900;position: relative;top:.3em;margin:0 -.08em 0 -.26em;}
}
.dialog-blank {
  background-color: black;
  opacity: 0.21;
  width: 100%;
  height: 100%;
}
.bg-dialog0-img {
  background-image: url('./s9/popup/01/bg.png');
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 35vw;
}
.bg-dialog1-img {
  background-image: url('./s9/popup/02/bg.png');
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 30vw;
}
.bg-dialog2-img {
  background-image: url('./s9/popup/03/bg.png');
  background-repeat: no-repeat;
  background-position: 0% 100%;
  background-size: 42vw;
}

::v-deep .el-dialog__header {
  padding: 0;
}
::v-deep .el-dialog__body {
  background: #6c7c7e;
  // height: 88vh;
}
.swiper-container {
  height: 93%;
  width: 100%;
  margin: 0;
  .slide {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: 50%;
    .caption {
      position: absolute;
      z-index: 1;
      color: #fff;
      right: 2vw;
      bottom: 0.8vw;
      font-size: 1.2vw;
      font-weight: bold;
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    }
  }
  .swiper-button-prev {
    // left: size(500);
    left: 0;
    bottom: 0;
    width: size(80);
    height: size(80);
    color: #fff;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
  .swiper-button-next {
    right: 0;
    color: #fff;
    width: size(80);
    height: size(80);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

    &::after {
      content: '';
    }
  }
}
/* 螢幕尺寸標準 */
// Mobile
/* 平板尺寸 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
  ::v-deep .slick-prev {
    z-index: 9999;
    left: 9px;
    background-image: url('./s9/popup/arrow_l.svg');
    background-repeat: no-repeat;
    width: 8vw;
    height: 8vw;

    &::before {
      content: '';
    }
  }
  ::v-deep .slick-next {
    z-index: 9999;
    right: 9px;
    background-image: url('./s9/popup/arrow_r.svg');
    background-repeat: no-repeat;
    width: 8vw;
    height: 8vw;

    &::before {
      content: '';
    }
  }
.title{
  font-size: size-m(24);
  width: 100%;
  text-align: center;margin: 0 0 0 0.1em;
  }
  .mb-s9-bg {
    background-image: url('./s9/mb-bg.jpg');
    background-size: cover;
    height: 177vw !important;
  }
  .mb-line {
    background-color: #fff;
    height: 1px;
    width: 28vw;
  }
  .mb-bg-dialog0-img {
    background-image: url('./s9/popup/01/bg.png');
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 90%;
  }
  .mb-bg-dialog1-img {
    background-image: url('./s9/popup/02/bg.png');
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 66%;
  }
  .mb-bg-dialog2-img {
    background-image: url('./s9/popup/03/bg.png');
    background-repeat: no-repeat;
    background-position: 0% 100%;
    background-size: 114%;
  }
  ::v-deep .el-dialog__body {
    background: #68838c;
    // height: 88vh;
    border: 1px solid white;
    padding: 0;
  }
  .swiper-container {
    height: 100%;
    width: 100%;
    margin: 0;
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      background-position: 50%;
      .caption {
        position: absolute;
        z-index: 1;
        color: #fff;
        right: 2vw;
        bottom: 0.8vw;
        font-size: 1.2vw;
        font-weight: bold;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .swiper-button-prev {
      // left: size(500);
      left: 0;
      bottom: 0;
      width: size(80);
      height: size(80);
      color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
    .swiper-button-next {
      right: 0;
      color: #fff;
      width: size(80);
      height: size(80);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;
      // background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;

      &::after {
        content: '';
      }
    }
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>

<script>
// @ is an alias to /src
import info from "@/info";
import { isMobile } from '@/utils'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'section9',
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      info,
      isMobile,
      activeIndex: 0,
      isDialogShow_0: false,
      isDialogShow_1: false,
      isDialogShow_2: false,
      slides0: [
        {
          img: require('./s9/popup/01/slider_1.jpg'),
          caption: '捷運先嗇宮站',
        },
        {
          img: require('./s9/popup/01/slider_2.jpg'),
          caption: '64快速道路',
        },
      ],
      slides1: [
        {
          img: require('./s9/popup/02/slider_1.jpg'),
          caption: '捷運先嗇宮站',
        },
        {
          img: require('./s9/popup/02/slider_2.jpg'),
          caption: '64快速道路',
        },
      ],
      slides2: [
        {
          img: require('./s9/popup/03/slider_1.jpg'),
          caption: '捷運先嗇宮站',
        },
        {
          img: require('./s9/popup/03/slider_2.jpg'),
          caption: '64快速道路',
        },
      ],
      swiperOptions: {
        effect: 'fade',
        speed: 1200,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section9 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 4500,
          disableOnInteraction: false,
        },
      },
    }
  },

  methods: {
    openDialog(index) {
      switch (index) {
        case 0:
          this.isDialogShow_0 = true
          this.activeIndex = 0
          break
        case 1:
          this.isDialogShow_1 = true
          this.activeIndex = 1
          break
        case 2:
          this.isDialogShow_2 = true
          this.activeIndex = 2
          break

        default:
          break
      }
    },
    closeDialog() {
      this.isDialogShow_0 = false
      this.isDialogShow_1 = false
      this.isDialogShow_2 = false
    },
    prev() {
      this.$refs.swiperSection9.$swiper.slidePrev()
    },
    next() {
      this.$refs.swiperSection9.$swiper.slideNext()
    },
  },

  mounted() {},
}
</script>
