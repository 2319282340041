<template>
  <article id="section6" class="section6">
    <!-- PC -->
    <div v-if="!isMobile" class="s6-bg relative" style="height: 100vh">
      <div
        class="absolute"
        style="width: 40vw; height: 70vh; top: -73%; right: 0"
      >
        <div class="relative w-full h-full">
          <img loading="lazy" src="./s5/pc-img_style.svg" 
          :alt="`${info.caseName}img_style`"
          />
        </div>
      </div>
      <div class="absolute" style="width: 45%; top: -90%; right: 0">
        <div class="relative w-full h-full">
          <img loading="lazy" src="./s5/pc-img.png" 
          :alt="`${info.caseName}img`"
          />
          <img loading="lazy"
            class="absolute blink"
            style="width: 10vw; right: 39%; top: 1%"
            src="./s5/pc-light_1.png"
          :alt="`${info.caseName}light_1`"
          />
          <img loading="lazy"
            class="absolute blink"
            style="width: 10vw; right: 16%; top: 12%"
            src="./s5/pc-light_1.png"
          :alt="`${info.caseName}light_1`"
          />
          <div class="caption-position">建築外觀3D透視圖</div>
        </div>
      </div>
      <img loading="lazy"
        data-aos="fade"
        data-aos-delay="500"
        class="absolute"
        style="top: -2vw; right: 0vw; width: 50vw"
        src="./s6/pc-style_1.png"
          :alt="`${info.caseName}style_1`"
      />
      <div class="flex">
        <div class="flex flex-col" style="padding-top: 5%; padding-left: 4%">
          <h3 class="mb-4 title" data-aos="fade-up"
        data-aos-delay="500">千坪莊園<span>壯闊盡收</span><b>。</b></h3>
          <p
            data-aos="fade-up"
            data-aos-delay="500"
            class="flex items-center text-primary-400 text-1.4 font-weight-900 tracking-wide font-secondary"
          >
            <span>The Aesthetics</span>
            <span class="s6-line mx-3" style="width: 2vw"></span>
            <span>內斂質樸。豪氣。獨樹一格</span>
          </p>
          <!-- Swiper -->
          <div class="mt-12 relative swiper_pc">
            <swiper :options="swiperOptions" ref="swiperSection6">
              <swiper-slide
                class="slide z-50"
                v-for="(slide, i) in slides"
                v-bind:key="i"
                v-bind:style="{
                  backgroundImage: `url(${slide.img})`,
                }"
              >
                <div class="caption">
                  {{ slide.caption }}
                </div>
              </swiper-slide>
            </swiper>
            <div
              class="absolute"
              style="
                background: #f8f8f8;
                right: -1vw;
                top: -1vw;
                height: 22vw;
                width: 42vw;
              "
            ></div>
            <img loading="lazy"
              data-aos="fade"
              data-aos-delay="500"
              class="absolute"
              style="width: 50vw; bottom: -6.5vw; left: -4.5vw; z-index: 1"
              src="./s6/pc-style_2.png"
          :alt="`${info.caseName}style_2`"
            />
            <!--  -->
            <div class="flex justify-end mt-3" style="width: 42vw">
              <div class="swiper-pagination" slot="pagination"></div>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-delay="500"
          class="flex flex-col"
          style="padding-top: 16%; padding-left: 9%"
        >
          <h4 class="flex mb-10 relative" style="margin-top: 10%">
            <span class="text-primary-500 text-1.6">內隱而大器</span>
            <span class="text-primary-300 text-1.6 mx-1">。</span>
            <span class="text-primary-500 text-1.6"
              >質樸而優雅 生活私密莊園</span
            >
            <img loading="lazy"
              class="absolute z-50 blink"
              style="width: 3.5vw; top: -8vw; left: -14vw"
              src="./s6/pc-star.svg"
          :alt="`${info.caseName}star`"
            />
          </h4>
          <div class="text-primary-500 text-1 leading-loose text-left mb-14">
            <p>千坪寬闊的基地展現大棟距生活空間</p>
            <p>都市中難得的悠然靜巷，基地周圍以景觀花園營造建築呼吸空間，</p>
            <p>形成自然的隱私屏障</p>
            <p>擁天光攬綠意，內斂卻更顯大器</p>
          </div>
          <span class="text-primary-200 text-2 font-weight-900 text-left"
            >尺度與生活，完美的平衡點</span
          >
        </div>
      </div>
    </div>

    <!-- Mobile -->
    <div v-if="isMobile" class="mb-s6-bg pb-16 relative" style="">
      <img loading="lazy"
        data-aos="fade"
        data-aos-delay="400"
        class="absolute"
        style="bottom: -1vw; left: 0vw"
        src="./s6/pc-style_2.png"
          :alt="`${info.caseName}style_2`"
      />
      <div data-aos="fade-up" data-aos-delay="400">
        <span
          class="text-primary-200 font-weight-900 font-secondary"
          style="font-size: 11vw; opacity: 0.23"
          >The Aesthetics</span
        >
      </div>

      <div
        data-aos="fade-up"
        data-aos-delay="400"
        class="flex flex-col"
        style="margin-left: 7vw"
      >
        <h3 class="mb-2 title">千坪莊園<span>壯闊盡收</span><b>。</b></h3>
        <h4 class="flex relative my-4">
          <span class="text-primary-500 text-4.5">內隱而大器</span>
          <span class="text-primary-300 text-4.5 mx-1">。</span>
          <span class="text-primary-500 text-4.5">質樸而優雅 生活私密莊園</span>
        </h4>
        <div
          class="text-primary-500 text-left text-3.5 mb-7 leading-relaxed"
          style="width: 90vw"
        >
          <p>千坪寬闊的基地展現大棟距生活空間</p>
          <p>
            都市中難得的悠然靜巷，基地周圍以景觀花園營造建築呼吸空間，形成自然的隱私屏障
          </p>
          <p>擁天光攬綠意，內斂卻更顯大器</p>
        </div>
        <div class="text-left">
          <span class="text-primary-200 text-5 font-weight-900"
            >尺度與生活，完美的平衡點</span
          >
        </div>
      </div>
      <div class="mt-10 relative swiper_mb">
        <img loading="lazy"
          class="absolute blink"
          style="width: 11vw; top: -13vw; right: 8vw"
          src="./s6/pc-star.svg"
          :alt="`${info.caseName}star`"
        />
        <swiper :options="swiperOptions" ref="swiperSection6">
          <swiper-slide
            class="slide z-50"
            v-for="(slide, i) in slides"
            v-bind:key="i"
            v-bind:style="{
              backgroundImage: `url(${slide.img})`,
            }"
          >
            <div class="caption">
              {{ slide.caption }}
            </div>
          </swiper-slide>
        </swiper>

        <!--  -->
        <div class="flex justify-center mt-3" style="width: 100vw">
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
// @ is an alias to /src
import info from "@/info";
import { isMobile } from '@/utils'
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'section6',
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  data() {
    return {
      info,
      isMobile,
      slides: [
        {
          img: require('@/projects/ar/s6/pc-slider_1.jpg'),
          caption: '日光前庭廣場3D情境示意圖',
        },
        {
          img: require('@/projects/ar/s6/pc-slider_2.jpg'),
          caption: '綠茵後苑草坪3D情境示意圖',
        },
        {
          img: require('@/projects/ar/s6/pc-slider_3.jpg'),
          caption: '人文交誼廳3D情境示意圖',
        },
        {
          img: require('@/projects/ar/s6/pc-slider_4.jpg'),
          caption: '健身房3D情境示意圖',
        },
      ],
      swiperOptions: {
        effect: 'fade',
        speed: 1200,
        spaceBetween: 30,
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        pagination: {
          el: '.section6 .swiper-pagination',
          clickable: true,
        },
        autoplay: {
          delay: 2000,
          disableOnInteraction: false,
        },
      },
    }
  },

  methods: {
    openBigImg() {
      this.$emit('openBigImg')
    },
  },
  mounted() {},

  created() {},
}
</script>

<style lang="scss" scoped>
@import '@/assets/style/function.scss';
@import '@/assets/style/animate.scss';
.blink {
  @include animate(fadeIn, 1s, 0);
  animation-iteration-count: infinite;
  animation-direction: alternate;
  pointer-events: none;
}
.title{
  text-align: left;
  font-size: size(62);
  letter-spacing: .07em;
  white-space: nowrap;
  span{
    font-weight: 900;margin: 0 0 0 0.3em;
    font-size: 1.01em;
  }
  b{font-weight: 900;position: relative;top:.3em;margin:0 -.08em 0 -.26em;}
}
/* 螢幕尺寸標準 */
.s6-line {
  background-color: #6e6e6e;
  height: 1px;
}

.swiper_pc {
  .swiper-container {
    height: calc(578 * 100vw / 1920);
    width: 42vw;
    height: 22vw;
    margin: 0;
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      .caption {
        position: absolute;
        z-index: 1;
        color: #fff;
        right: 1vw;
        bottom: 0.8vw;
        font-size: 1vw;
        font-weight:bold;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .swiper-button-prev {
      left: 0;
      bottom: 0;
      width: size(80);
      height: size(80);
      color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;

      &::after {
        content: '';
      }
    }
    .swiper-button-next {
      right: 0;
      color: #fff;
      width: size(80);
      height: size(80);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;

      &::after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      margin-left: 16px;
      border-radius: 0%;
      background: white;
      width: size(70);
      height: size(5);
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: #ababab;
      }
    }
  }
}
.swiper_mb {
  .swiper-container {
    height: calc(578 * 100vw / 1920);
    width: 100%;
    height: 57vw;
    margin: 0;
    .slide {
      height: 100%;
      width: 100%;
      background-size: cover;
      .caption {
        position: absolute;
        z-index: 1;
        color: #fff;
        // left: 80vw;
        right: 5vw;
        bottom: 3vw;
        font-size: 3.5vw;
        font-weight: bold;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
    }
    .swiper-button-prev {
      left: 0;
      bottom: 0;
      width: size(80);
      height: size(80);
      color: #fff;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: left;

      &::after {
        content: '';
      }
    }
    .swiper-button-next {
      right: 0;
      color: #fff;
      width: size(80);
      height: size(80);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: right;

      &::after {
        content: '';
      }
    }
  }
  ::v-deep .swiper-pagination {
    position: relative;
    .swiper-pagination-bullet {
      margin-left: 16px;
      border-radius: 0%;
      background: white;
      width: 8vw;
      height: 1vw;
      opacity: 1;
      &.swiper-pagination-bullet-active {
        background: #ababab;
      }
    }
  }
}
.caption-position {
  position: absolute;
  z-index: 1;
  color: #fff;
  right: 1%;
  // top: -5%;
  bottom: 7%;
  font-size: 1vw;
        font-weight:bold;
  text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
@media screen and (min-width: 1680px) and (max-width: 1919px) {
  // .caption-position {
  //   top: -4%;
  // }
}
@media screen and (min-width: 1440px) and (max-width: 1679px) {
  // .caption-position {
  //   top: -4%;
  // }
}
@media screen and (min-width: 1280px) and (max-width: 1439px) {
  // .caption-position {
  //   top: 9%;
  // }
}

/* 手機尺寸 */
@media only screen and (max-width: 767px) {
.title{
  font-size: size-m(34.5);
  }
  .swiper_mb .swiper-container .slide .caption {
    right: 6vw;
  }
}

// 避免內容電腦過渡平板時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1199.98px) {
}

// 避免過度到 1280 x 720 時，設計未考量的調整
@media only screen and (min-width: 1025px) and (max-width: 1280px) {
}
</style>
